import acb from '../static/images/acb.jpg';
import aucklandGold from '../static/images/auckland-dpp-gold.png';
import aucklandSilver from '../static/images/auckland-dpp-silver.png';
import ausae from '../static/images/au-saue.png';
import beia from '../static/images/beia.svg';
import bnh from '../static/images/bnh.jpg';
import nzsae from '../static/images/nzsae.png';
import nzSustainable from '../static/images/nz-sustainable-event-logo.png';
import pureNewZealand from '../static/images/pure-new-zealand.png';

export const supporters = [
];

export const partners = [
    {
        logo: acb,
        name: 'Auckland Convention Bureau',
        link: 'https://www.aucklandnz.com'
    },
    {
        logo: beia,
        name: 'Business Events Industry Aotearoa',
        link: 'https://beia.co.nz/',
    },
    {
        logo: nzsae,
        name: 'THE ASSOCIATION FOR ASSOCIATIONS in AOTEAROA',
        link: 'https://www.nzsae.org.nz/'
    },
    {
        logo: ausae,
        name: 'The Australasian Society of Association Executives',
        link: 'https://ausae.org.au/'
    },
    {
        logo: bnh,
        name: 'Business North Harbour',
        link: 'http://businessnh.org.nz/'
    },
    {
        logo: pureNewZealand,
        name: '100% Pure New Zealand',
        link: 'https://businessevents.newzealand.com/en/'
    },
    {
        logo: nzSustainable,
        name: 'NZ Sustainable Event Supplier',
        link: 'https://www.sustainableevents.co.nz/suppliers'
    },
    {
        logo: aucklandGold,
        name: 'Auckland Destination Partner Gold',
        link: 'https://www.aucklandnz.com/destination-partnership-programme/meet-our-partners'
    },
    {
        logo: aucklandSilver,
        name: 'Auckland Destination Partner Silver',
        link: 'https://www.aucklandnz.com/destination-partnership-programme/meet-our-partners'
    },
];
