/**
 * Chunk an array into smaller arrays
 * @param {Array} array 
 * @param {Number} chunkSize 
 * @returns {Array}
 */
export function arrayChunk(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }

    // Array of arrays
    return result;
}
